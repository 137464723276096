.roll {
  display: inline-block;
}

.roll::after {
  content: " |";
}

.lastRoll {
  display: inline-block;
}

.prevRolls {
  font-size: 1.6rem;
  width: 800px;
  margin: 0 auto;
}

@media only screen
and (max-device-width : 1060px) {
  .prevRolls {
    font-size: 4rem;
    width: 98%;
  }
}