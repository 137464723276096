.diceImages {
  display: flex;
  align-items: center;
  justify-content: center;
  vertical-align: center;
  flex-wrap: wrap;
  align-content: center;
  font-family: 'Open Sans', sans-serif;
  
  background: linear-gradient(top, #222, #333);
}

.first-face {
  display: flex;
  justify-content: center;
  align-items: center;
}

.dice {
  padding: 4px;
  border: 1px solid #111111;
  background-color: #383838;
  width: 104px;
  height: 104px;
  border-radius: 10%;
  margin-right : 10px;
}

.dice.active {
  background-color: #777;
}

.dot{
  display: block;
  border: 1px solid #444444;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background-color:white;
}

.second-face{
  display: flex ;
  justify-content: space-between;
}

.second-face .dot:nth-of-type(2) {
  align-self: flex-end;
}

.third-face {
  display: flex;
  justify-content: space-between;
}

.third-face .dot:nth-of-type(1) {
  align-self :flex-end;
}

.third-face .dot:nth-of-type(2) {
  align-self :center;
}


.fourth-face , .sixth-face, .fifth-face{
  display: flex;
  justify-content: space-between;
}

.fourth-face .column , .sixth-face .column, .fifth-face .column{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.fifth-face .column:nth-of-type(2) {
  justify-content: center;
}

.setDiceButtons {
  text-align: center;
}

.setDiceButton, .removeDiceButton {
  font-size: 1.3rem;
  padding: 9px 12px;
  margin: 0 18px;
}

@media only screen
and (max-device-width : 1060px) {
  .dice {
    width: 240px;
    height: 240px;
  }
  .dot {
    width: 48px;
    height: 48px;
  }
  .setDiceButton, .removeDiceButton {
    width: 300px;
    height: 150px;
    font-size: 3rem;
  }
}
