.percentagesTableContainer {
  display: block;
  text-align: center;
  width: 100vw;
}

.percentagesTable thead td {
  font-weight: 900;
  font-size: 3rem;
  padding: 5px 35px;
}

.percentagesTable tbody td {
  text-align: center;
  font-size: 1.5rem;
}

@media only screen
and (max-device-width : 1060px) {
  .percentagesTable tbody td {
    text-align: center;
    font-size: 4.5rem;
    padding: 8px 10px;
    border: 1px solid black;
  }
}