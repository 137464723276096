.piecesContainer {
  text-align: center;
  width: 100%;
}

.piecesContainer .piece {
  width: 20px;
  height: 60px;
  border: 1px solid #666666;
  display: inline-block;
  margin: 50px;
  cursor: pointer;
}

.piecesContainer .piece.active {
  border: 3px solid black;
}

.piecesContainer .piece.red {
  background-color: red;
}

.piecesContainer .piece.blue {
  background-color: blue;
}

.piecesContainer .piece.green {
  background-color: green;
}

.piecesContainer .piece.brown {
  background-color: #964b00;
}

.piecesContainer .piece.orange {
  background-color: #ff7700;
}

.piecesContainer .piece.white {
  background-color: white;
}

@media only screen
and (max-device-width : 1060px) {
  .piecesContainer .piece {
    width: 50px;
    height: 150px;
    border: 1px solid #666666;
    display: inline-block;
    margin: 50px;
    cursor: pointer;
  }
  .playerNameInput {
    font-size: 3.5rem;
    display: block;
  }
  .addPlayerButton, .resetPlayersButton {
    width: 300px;
    height: 150px;
    font-size: 3rem;
    display: block;
    margin-top: 40px;
  }
  .resetPlayersButton {
    color: red;
  }
}